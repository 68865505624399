// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-company-index-tsx": () => import("./../../../src/pages/about/company/index.tsx" /* webpackChunkName: "component---src-pages-about-company-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-en-about-company-index-tsx": () => import("./../../../src/pages/en/about/company/index.tsx" /* webpackChunkName: "component---src-pages-en-about-company-index-tsx" */),
  "component---src-pages-en-about-index-tsx": () => import("./../../../src/pages/en/about/index.tsx" /* webpackChunkName: "component---src-pages-en-about-index-tsx" */),
  "component---src-pages-en-contact-index-tsx": () => import("./../../../src/pages/en/contact/index.tsx" /* webpackChunkName: "component---src-pages-en-contact-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-privacy-index-tsx": () => import("./../../../src/pages/en/privacy/index.tsx" /* webpackChunkName: "component---src-pages-en-privacy-index-tsx" */),
  "component---src-pages-en-projects-index-tsx": () => import("./../../../src/pages/en/projects/index.tsx" /* webpackChunkName: "component---src-pages-en-projects-index-tsx" */),
  "component---src-pages-en-services-index-tsx": () => import("./../../../src/pages/en/services/index.tsx" /* webpackChunkName: "component---src-pages-en-services-index-tsx" */),
  "component---src-pages-en-topics-index-tsx": () => import("./../../../src/pages/en/topics/index.tsx" /* webpackChunkName: "component---src-pages-en-topics-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-topics-index-tsx": () => import("./../../../src/pages/topics/index.tsx" /* webpackChunkName: "component---src-pages-topics-index-tsx" */),
  "component---src-templates-projects-detail-en-tsx": () => import("./../../../src/templates/projectsDetailEn.tsx" /* webpackChunkName: "component---src-templates-projects-detail-en-tsx" */),
  "component---src-templates-projects-detail-tsx": () => import("./../../../src/templates/projectsDetail.tsx" /* webpackChunkName: "component---src-templates-projects-detail-tsx" */),
  "component---src-templates-studio-detail-en-tsx": () => import("./../../../src/templates/studioDetailEn.tsx" /* webpackChunkName: "component---src-templates-studio-detail-en-tsx" */),
  "component---src-templates-studio-detail-tsx": () => import("./../../../src/templates/studioDetail.tsx" /* webpackChunkName: "component---src-templates-studio-detail-tsx" */),
  "component---src-templates-topics-detail-en-tsx": () => import("./../../../src/templates/topicsDetailEn.tsx" /* webpackChunkName: "component---src-templates-topics-detail-en-tsx" */),
  "component---src-templates-topics-detail-tsx": () => import("./../../../src/templates/topicsDetail.tsx" /* webpackChunkName: "component---src-templates-topics-detail-tsx" */)
}

